<template>
  <div class="payment-status" v-cloak>
    <!--失败时： 用icon-iconfontguanbi fail替换icon-duihao2 bg-color-red-->
    <div
      class="iconfont icon-duihao2"
      v-if="orderInfo.status == 1"
      style="background: #2291f8"
    ></div>
    <div class="iconfont icon-iconfontguanbi fail" v-else></div>
    <!-- 失败时：订单支付失败 -->
    <div class="status" v-if="orderInfo.status == 1">订单支付成功</div>
    <div class="status" v-else>订单支付失败</div>
    <div class="wrapper">
      <div class="item acea-row row-between-wrapper">
        <div>订单编号</div>
        <div class="itemCom">{{ orderInfo.order_id }}</div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div>下单时间</div>
        <div class="itemCom">{{ orderInfo.createTime }}</div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div>支付方式</div>
        <div class="itemCom">{{ payWay }}</div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div>支付金额</div>
        <div class="itemCom">{{ orderInfo.price }}</div>
      </div>
    </div>

    <div>
      <div class="returnBnt bg-color-red" @click="goPages()">查看订单</div>
      <div class="returnBnt cart-color" @click="goPages('/shop')">返回首页</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getOrder } from "@api/public";
import { vipOrder } from "@api/user";
import { isWeixin } from "@utils";

export default {
  props: {},
  data: function() {
    return {
      id: "",
      status: 0,
      order_id: '',
      sign: '',
      msgContent: "",
      payWay: '',
      orderInfo: {},
      isWeixin: isWeixin(),
      renewal:''
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.id = this.$route.params.id;
    this.order_id = this.$route.query.order_id;
    this.sign = this.$route.query.sign || '';
    this.renewal = this.$route.query.renewal || ''
    this.getOrderInfo();
    const way = this.$route.query.way;
    if(way == 'yue') {
      this.payWay = '余额支付'
    } else if(way == 'alipay') {
      this.payWay = '支付宝支付'
    } else if(way == 'weixin') {
      this.payWay = '微信支付'
    } else if(way == 'weixin-h5') {
      this.payWay = 'H5微信支付'
    }
  },
  methods: {
    getOrderInfo() {
      if(this.sign == 'vip') {
        vipOrder(this.order_id).then(res => {
          this.orderInfo = res.data;
        })
      } else {
        getOrder(this.order_id).then(res => {
          this.orderInfo = res.data;
        })
      }      
    },

    goPages(path) {
      if(path) {
        this.$router.push(path)
      } else {
        if(this.sign == 'vip') {
          if(this.renewal == 'renewal') {
             this.$router.push('/user/RenewRecord')    
          }
          else {
            this.$router.push(`/user/dredgeVip/${this.orderInfo.level_id}`)     
          }
          
        } else {
          this.$router.push('/user/MyLesson')          
        }
      }
    }
  }
};
</script>
